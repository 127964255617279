/** @jsx jsx */
import { jsx, Box, Heading } from "theme-ui"
import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import Slicezone from "../components/Slicezone"

export default function Alp({ data }) {
  const alp = data.prismicAlps.data
  return (
    <Layout>
      <Box variant="layout.wrapper.medium">
        <Heading as="h1" sx={{mb: 2}}>{alp.alp_name.text}</Heading>
        <Img fluid={alp.alp_image.fluid} />
        <Box sx={{mt: 3, mb: 4}}>
          <Heading as="h4" sx={{color: "navy75", fontWeight: "500 "}}><span sx={{fontWeight: "700"}}>Owners/Operators:</span> {alp.owners.text}</Heading>
          <Heading as="h4" sx={{color: "navy75", fontWeight: "500"}}><span sx={{fontWeight: "700"}}>Cheeses Produced:</span> {alp.cheeses.text}</Heading>
        </Box>
        <Slicezone slices={alp.body} />
      </Box>
    </Layout>
  )
}

export const query = graphql`
  query AlpQuery($id: String!) {
    prismicAlps(id: { eq: $id }) {
      data {
        alp_name {
          text
        }
        alp_image {
          fluid(maxWidth: 5000) {
            ...GatsbyPrismicImageFluid
          }
        }
        owners {
          text
        }
        cheeses {
          text
        }
        body {
          ... on PrismicAlpsBodyText {
            slice_type
            primary {
              text {
                html
              }
            }
          }
          ... on PrismicAlpsBodyMedia {
            slice_type
            primary {
              media {
                url
              }
            }
          }
          ...on PrismicAlpsBodyImageGallery {
            slice_type
            items {
              gallery_image {
                fluid(maxWidth: 5000) {
                  ...GatsbyPrismicImageFluid
                }
              }
              image_captions {
                text
              }
            }
          }
        }
      }
    }
  }
`
